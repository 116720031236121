import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createTransaction, getMonthlyPayments } from "../../../api/payments";
import useAuth from "../../../context/useAuth";
import PaginationComponent from "../../pagination/pagination";
import { twoDecimalInputChange } from "../../../utils/twoDecimalInputChange";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Payments = () => {
  const { locations } = useAuth();
  const [payments, setPayments] = useState<any[]>([]);
  const [city, setCity] = useState<any | null>(null);
  const [month, setMonth] = useState<Date | null>(null);
  const [year, setYear] = useState<Date | null>(null);
  const [description, setDescription] = useState("");
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paymentID, setPaymentID] = useState("");
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [openMonth, setOpenMonth] = useState<boolean>(false);
  const [openYear, setOpenYear] = useState<boolean>(false);

  useEffect(() => {
    getPayments();
  }, [month, year, city, page]);

  const handleNext = () => {
    setPage((currentPage) => currentPage + 1);
  };

  const handlePrev = () => {
    setPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const getPayments = async () => {
    let query = "";
    let filters = [
      {
        filterName: "location",
        filterValue: city?.value || null,
      },
      {
        filterName: "month",
        filterValue: month ? dayjs(month).month().toString() : null,
      },
      {
        filterName: "year",
        filterValue: year ? dayjs(year).year().toString() : null,
      },
      {
        filterName: "page",
        filterValue: page.toString(),
      },
    ];

    filters.map((filter, index) => {
      if (filter.filterValue) {
        if (index > 0 && query.length > 0) {
          query = `${query}&${filter.filterName}=${filter.filterValue}`;
          return;
        }
        query = `${query}${filter.filterName}=${filter.filterValue}`;
      }
    });
    const res = await getMonthlyPayments(query);

    if ("error" in res) {
      return toast.error(res.message ? res.message : "Server Error");
    }
    setPayments(res.transactionAmount);
    setTotalPages(res.totalPages);
  };

  const addTransaction = async () => {
    setTransactionLoading(true);
    if (amount <= 0) {
      setTransactionLoading(false);
      return toast.error("Shuma duhet te jete me e madhe se 0");
    }
    const res = await createTransaction(amount, paymentID, description);
    if ("error" in res) {
      setDescription("");
      setTransactionLoading(false);

      return toast.error(res.message ? res.message : "Server Error");
    }

    toast.success("Transaction created successfully");
    setTransactionLoading(false);
    setModal(false);
    setPaymentID("");
    setAmount(0);
    setDescription("");
    getPayments();
  };

  const isButtonVisible = (payment: any) => {
    const paymentDate = new Date(payment.month_year);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const paymentYear = paymentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const paymentMonth = paymentDate.getMonth();
    const isSameYear = currentYear === paymentYear;
    const isSameMonth = currentMonth === paymentMonth;
    const isNextMonthSameYear =
      currentMonth === paymentMonth + 1 && currentYear === paymentYear;
    const isJanForLastDec =
      currentYear === paymentYear + 1 &&
      currentMonth === 0 &&
      paymentMonth === 11;
    const isBefore10th = currentDate.getDate() < 10;
    const canDisplayButton =
      (isSameYear && isSameMonth) ||
      (isNextMonthSameYear && isBefore10th) ||
      (isJanForLastDec && isBefore10th);
    return (
      canDisplayButton && (
        <Button
          onClick={() => {
            setModal(true);
            setPaymentID(payment._id);
          }}
          variant="outlined"
        >
          Add New Transaction
        </Button>
      )
    );
  };

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Typography sx={{ p: 2, fontSize: "26px" }}>Payments</Typography>
      <Box
        component="form"
        display={"flex"}
        sx={{ width: "100%", p: 2, flexDirection: { xs: "column", lg: "row" } }}
      >
        <FormControl fullWidth sx={{ mr: 2 }}>
          <TextField
            id="city"
            value={city || ""}
            onChange={(e) => setCity(e.target.value)}
            select
            size="small"
            label="City"
            InputProps={{
              startAdornment: city && (
                <IconButton
                  onClick={() => {
                    setCity(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          >
            {locations.map((city: any) => (
              <MenuItem key={city.value} value={city}>
                {city.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <LocalizationProvider>
          <FormControl fullWidth sx={{ mr: 2, mt: { xs: 1, lg: 0 } }}>
            <DatePicker
              open={openMonth}
              onOpen={() => setOpenMonth(true)}
              onClose={() => setOpenMonth(false)}
              label="Month"
              views={["month"]}
              onChange={(newVal: any) => setMonth(newVal)}
              slotProps={{
                textField: {
                  onClick: () => setOpenMonth(true),
                  size: "small",
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
        <LocalizationProvider>
          <FormControl fullWidth sx={{ mr: 2, mt: { xs: 1, lg: 0 } }}>
            <DatePicker
              open={openYear}
              onOpen={() => setOpenYear(true)}
              onClose={() => setOpenYear(false)}
              label="Year"
              views={["year"]}
              minDate={dayjs("2021")}
              maxDate={dayjs(new Date())}
              onChange={(newVal: any) => setYear(newVal)}
              slotProps={{
                textField: {
                  onClick: () => setOpenYear(true),
                  size: "small",
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            minHeight: "500px",
          }}
        >
          <Box sx={{ width: { xs: "150%", sm: "100%" } }}>
            <Box
              sx={{
                display: "flex",
                p: 2,
                pr: 5,

                ">p": {
                  width: "25%",
                  fontWeight: "bold",
                },
              }}
            >
              <Typography>Date</Typography>
              <Typography>Location</Typography>
              <Typography>Total</Typography>
              <Typography>Handover</Typography>
            </Box>
            {payments.map((payment, index) => (
              <Accordion
                key={index}
                elevation={0}
                sx={{
                  "&.Mui-expanded": {
                    margin: 0,
                    backgroundColor: `rgba(0, 0, 0, 0.02)`,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      ">p": {
                        width: "25%",
                      },
                    }}
                  >
                    <Typography>
                      {monthNames[new Date(payment.month_year).getMonth()]}{" "}
                      {new Date(payment.month_year).getFullYear()}
                    </Typography>
                    <Typography>{payment.location.name}</Typography>
                    <Typography>{payment.totalAmount}€</Typography>
                    <Typography>Fikreti/Floranda</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      mx: "auto",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        Transactions
                      </Typography>
                      {isButtonVisible(payment)}
                    </Box>
                    <Dialog
                      open={modal}
                      onClose={() => {
                        setModal(false);
                        setPaymentID("");
                        setAmount(0);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle>ADD TRANSACTION</DialogTitle>
                      <DialogContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            size="small"
                            type="number"
                            sx={{ mt: 2, width: " 45%" }}
                            value={amount === 0 ? "" : amount}
                            onChange={twoDecimalInputChange(setAmount)}
                            label="Amount"
                          />
                          <TextField
                            size="small"
                            sx={{ mt: 2, width: " 45%" }}
                            value={description}
                            onChange={(e: any) =>
                              setDescription(e.target.value)
                            }
                            label="Description"
                          />
                        </Box>

                        <LoadingButton
                          onClick={addTransaction}
                          sx={{
                            mt: 2,
                            width: " 100%",
                            display: "flex",
                          }}
                          variant="outlined"
                          size="medium"
                          loading={transactionLoading}
                        >
                          Submit
                        </LoadingButton>
                      </DialogContent>
                    </Dialog>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 2,
                        ">p": {
                          width: "33%",
                          textAlign: "center",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <Typography>Amount</Typography>
                      <Typography>Description</Typography>
                      <Typography>Creation Date</Typography>
                      <Typography>Created By</Typography>
                    </Box>
                    {payment.transactions.map(
                      (transaction: any, index: any) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              ">p": {
                                width: "33%",
                                textAlign: "center",
                              },
                              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Typography sx={{ mt: 2 }}>
                              {transaction.amount}€
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                              {transaction.description}
                            </Typography>

                            <Typography sx={{ mt: 2 }}>
                              {`${new Date(
                                transaction.created_at
                              ).getDate()} - ${
                                monthNames[
                                  new Date(transaction.created_at).getMonth()
                                ]
                              } - ${new Date(
                                transaction.created_at
                              ).getFullYear()}`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                              {transaction.created_by.first_name}{" "}
                              {transaction.created_by.last_name}
                            </Typography>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                  <Typography sx={{ textAlign: "center" }}>
                    Handover: {payment.transactionsTotalAmount}€
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
        <PaginationComponent
          handleNext={handleNext}
          handlePrev={handlePrev}
          page={page}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};

export default Payments;
